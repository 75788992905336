<template>
  <b-card-text>
    <div>
      <div
        id="modal-body"
        class="modal-body"
      >
        <p>
          Na {{ title }}, privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/associados. Por isso, esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de associados ou outras pessoas que acessam ou usam nosso site.
          Ao utilizar nossos serviços, você entende que coletaremos e usaremos suas informações pessoais nas formas descritas nesta Política, sob as normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.
        </p>
        <p>Dessa forma, a Sociedade Norte e Nordeste de Oftalmologia, doravante denominada simplesmente como “SBG” no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.</p>
        <br>
        <p>
          1. Quais dados coletamos sobre você e para qual finalidade?
          Nosso site coleta e utiliza alguns dados pessoais seus, para viabilizar a prestação de serviços e aprimorar a experiência de uso e marketing.
        </p>
        <br>
        <p>1.1. Dados pessoais fornecidos pelo titular.</p>
        <p>Dado: Nome</p>
        <p>Finalidade: Identificar o usuário dentro e fora da plataforma para personalizar mensagens, nossa ou de parceiros.</p>
        <br>
        <p>Dado: CPF</p>
        <p>Finalidade: Identificar o usuário dentro e fora da plataforma validar certificados e qualquer informação junto da {{ title }}.</p>
        <br>
        <p>Dado: Email</p>
        <p>Finalidade: Entrar em nossos serviços da {{ title }} e recebimento de Marketing da {{ title }} ou de seus parceiros.</p>
        <br>
        <p>Dado: Profissão</p>
        <p>Finalidade: Identificar e limitar o acesso a conteúdos direcionados para profissionais específicos.</p>
        <br>
        <p>Dado: Numero de telefone ou celular.</p>
        <p>Finalidade: Envio de SMS, mensagem de WhatsApp ou qualquer outro meio que permita a comunicação e marketing entre o usuário, {{ title }} e parceiros.</p>
        <br>
        <p>Dado: CEP</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Rua</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>

        <p>Dado: complemento do endereço.</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Numero da casa.</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Bairro.</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Cidade.</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Estado</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: País.</p>
        <p>Finalidade: Identificar a localidade do usuário para envio de carta, premio ou qualquer comunicação física que necessite realizar, direcionamento de campanha publicitarias direcionada para aquela região pela {{ title }} ou parceiros.</p>
        <br>
        <p>Dado: Senha.</p>
        <p>Finalidade: Permitir acesso a plataforma.</p>
        <br>
        <p>Dado: Data de nascimento.</p>
        <p>Finalidade: Envio de mensagem personalizada de aniversário, promoções de aniversario da {{ title }} ou parceiros.</p>
        <br>
        <p>
          Dado: CRM.
        </p><p>
          Finalidade: Validar se o usuário realmente é medico e verificar sua especialidade.
          <br>
        </p><p>Dado: CRM_UF.</p>
        <p>Finalidade: Validar se o usuário realmente é medico e verificar sua especialidade.</p>
        <br>
        <p>Dado: Pronome.</p>
        <p>Finalidade: Colocar nas tarjas o pronome como o usuário se identifica e prefere ser chamado. </p>
        <br>
        <p>Dado: Nome para crachá.</p>
        <p>Finalidade: Utilizar para divulgação de palestras ou atividades que não cabe o nome inteiro ou exista preferencia por outro nome por parte do palestrante.</p>
        <br>
        <p>Dado: Nome para crachá.</p>
        <p>Finalidade: Utilizar para divulgação de palestras ou atividades que não cabe o nome inteiro ou exista preferencia por outro nome por parte do palestrante.</p>
        <br>
        <p>Dado: Mini currículo.</p>
        <p>Finalidade: Utilizar para divulgação de palestras ou das atividades associadas ao palestrante. </p>
        <br>
        <p>Dado: Link para o currículo lattes </p>
        <p>Finalidade: Utilizar para divulgação de palestras ou das atividades associadas ao palestrante. </p>
        <br>
        <p>Dado: Foto do perfil.</p>
        <p>Finalidade: Utilizada para identificação entre usuários da plataforma.</p>
        <br>
        <p>Dado: Outro documento estrangeiro.</p>
        <p>Finalidade: Utilizada para identificação do usuário na plataforma quando o mesmo não é</p> brasileiro.
        <br>
        <p>Dado: Instituição de ensino.</p>
        <p>Finalidade: Utilizada para verificar se o estudante faz parte daquela instituição e emitir certificado de eventuais trabalhos científicos apresentados dentro da plataforma.</p>
        <br>
        <p>1.2. Dados pessoais coletados automaticamente.</p>
        <br>
        <p>1.2.1 Em função do acesso ao Site Institucional, Plataforma {{ title }} 5.0 ou eventuais produtos {{ title }}, será coletada as seguintes informações para estatísticas, segurança ou para diagnosticar ou administrar eventuais problemas:</p>
        <br>
        <p>Dado: Imagens de uso.</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>
        <p>Dado: Nomes dos provedores de acesso e de serviços de internet.</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>
        <p>Dado: Forma de acesso ao Site (e.g., tipo de navegador, sistema operacional e dispositivo utilizados)</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>
        <p>Dado: Endereço de IP</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>
        <p>Dado: Região geográfica, data e horário de acesso</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>
        <p>Dado: Atividade realizada no Site e a(s) página(s) acessadas antes ou depois do Site.</p>
        <p>Finalidade: Monitoramento de eventuais problemas e aprimoramento do uso para os usuários. </p>
        <br>

        <p>1.2.2 Também poderão ser enviados cookies para o dispositivo utilizado pelo Titular com o propósito de aperfeiçoar o desempenho do Site e personalizar a experiência de acesso.</p>
        <br>
        <p>1.2.3 O Titular tem toda liberdade para bloquear referidos cookies, sendo certo, no entanto, que tal bloqueio poderá impedir o fornecimento de um acesso personalizado e, eventualmente, o funcionamento de determinadas funcionalidades do Site.</p>
        <br>
        <p>2. Como coletamos os seus dados?</p>
        <p>Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma:</p>
        <br>
        <p>Formulário de inscrição na plataforma </p>
        <p>Formulário de atualização de perfil </p>
        <p>Formulário de inscrição em subprodutos ou eventos na plataforma</p>
        <p>Formulário presencial em eventos</p>
        <p>Formulário de associação </p>
        <br>
        <p>2.1. Consentimento</p>
        <p>É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a {{ title }} a tratar seus dados e a fornecê-los aos parceiros listados nessa politica.</p>
        <br>
        <p>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento. </p>
        <br>
        <p>O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da {{ title }} para com seus usuários/associados, seguindo as regulações legislativas pertinentes.</p>
        <br>
        <p>Ao utilizar os serviços da {{ title }} e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.</p>
        <br>
        <p>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.</p>
        <br>
        <p>É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do site que dependa da operação. Tais consequências serão informadas previamente.</p>
        <br>
        <p>3. Quais são os seus direitos?</p>
        <p>A {{ title }} assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:</p>
        <br>
        <p>Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.</p>
        <p>Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.</p>
        <p>Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.</p>
        <p>Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.</p>
        <p>Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a {{ title }} trata a seu respeito.</p>
        <p>
          Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.
          Revogar seu consentimento, desautorizando o tratamento de seus dados.
        </p>
        <p>Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.</p>
        <p>4. Como você pode exercer seus direitos de titular?</p>
        <p>Para exercer seus direitos de titular, você deve entrar em contato com a (nome empresarial simplificado) através dos seguintes meios disponíveis:</p>
        <br>
        <p>Email: dados@{{ title }}.com.br </p>
        <br>
        <p>De forma a garantir a sua correta identificação como titular dos dados pessoais objeto da solicitação, é possível que solicitemos documentos ou demais comprovações que possam comprovar sua identidade. Nessa hipótese, você será informado previamente.</p>
        <br>
        <p>5. Como e por quanto tempo seus dados serão armazenados?</p>
        <p>Seus dados pessoais coletados pela {{ title }} e serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.</p>
        <br>
        <p>De modo geral, seus dados serão mantidos enquanto a relação contratual entre você e a {{ title }} perdurar. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados, a saber:</p>
        <br>
        <p>I – cumprimento de obrigação legal ou regulatória pelo controlador;</p>
        <br>
        <p>II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
        <br>
        <p>III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou</p>
        <br>
        <p>IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
        <br>
        <p>Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados. </p>
        <br>
        <p>O armazenamento de dados coletados pela {{ title }} reflete o nosso compromisso com a segurança e privacidade dos seus dados. Empregamos medidas e soluções técnicas de proteção aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Além disso, também contamos com medidas de segurança apropriadas aos riscos e com controle de acesso às informações armazenadas.</p>
        <br>
        <p>6. O que fazemos para manter seus dados seguros?</p>
        <p>Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.</p>
        <br>
        <p>Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.</p>
        <br>
        <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
        <br>

        <p> I. Apenas pessoas autorizadas têm acesso a seus dados pessoais</p>
        <p> II. O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade</p>
        <p> III. Seus dados pessoais são armazenados em ambiente seguro e idôneo.</p>
        <p> IV. A {{ title }} se compromete a adotar as melhores posturas para evitar incidentes de segurança. Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos de hackers, ou também em decorrência da negligência ou imprudência do próprio usuário/cliente.</p>
        <p> V. Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as disposições da Lei Geral de Proteção de Dados.</p>
        <br>
        <p>7. Com quem seus dados podem ser compartilhados?</p>
        <p>Tendo em vista a preservação de sua privacidade, a {{ title }} não compartilhará seus dados pessoais com nenhum terceiro não autorizado. </p>
        <br>
        <p>Seus dados poderão ser compartilhados com nossos parceiros comerciais: </p>
        <br>
        <p>Allergan produtos farmacêuticos LTDA, inscrito no CNPJ sob o nº 43.426.626/0001-77</p>
        <br>
        <p>OVNI AUDIO VIDEO PRODUCOES LTDA, inscrito no CNPJ sob o nº 12.924.647/0001-99</p>
        <br>
        <p>Wave Design Studio T L D de Andrade Eireli, inscrito no CNPJ sob o nº 31.507.906/0001-20</p>
        <br>
        <p>JOHNSON & JOHNSON DO BRASIL INDUSTRIA E COMERCIO DE PRODUTOS PARA SAUDE LTDA. inscrito no CNPJ sob o nº 54.516.661/0001-01</p>
        <br>
        <p>PHICODING, inscrito no CNPJ sob o nº 31.713.293/0001-88</p>
        <br>
        <p>The Rocket Science Group, LLC, inscrito no EIM sob o nº 58-2554149</p>
        <br>
        <p>ONESIGNAL, INC, inscrito no CSS sob C3433594</p>
        <br>
        <p>Estes recebem seus dados na medida do necessário para o cumprimento dos contratos e nossos contratos são orientados pelas normas de proteção de dados do ordenamento jurídico brasileiro.</p>
        <br>
        <p>Todavia, nossos parceiros têm suas próprias Políticas de Privacidade, que podem divergir desta. Recomendamos a leitura desses documentos, que você pode acessar aqui:</p>
        <br>
        <p>Allergan produtos farmacêuticos LTDA: <a href="https://www.allergan.com.br/pt-br/privacy">Verificar aqui</a></p>
        <br>
        <p>JOHNSON & JOHNSON DO BRASIL INDUSTRIA E COMERCIO DE PRODUTOS PARA SAUDE LTDA. <a href="https://jnjvisionpro.com.br/politica-de-privacidade">Verificar aqui</a></p>
        <br>
        <p>OVNI AUDIO VIDEO PRODUCOES LTDA: <a href="#">Verificar aqui</a></p>
        <br>
        <p>PHICODING: <a href="#">Verificar aqui</a></p>
        <br>
        <p>Wave Design Studio T L D de Andrade Eireli: <a href="#">Verificar aqui</a></p>
        <br>
        <p>The Rocket Science Group, LLC: <a href="https://mailchimp.com/legal/privacy/">Verificar aqui</a></p>
        <br>
        <p>ONESIGNAL, INC: <a href="https://onesignal.com/privacy_policy/">Verificar aqui</a></p>
        <br>
        <p>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</p>
        <br>
        <p>I – Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.</p>
        <br>
        <p>II – Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática.</p>
        <br>
        <p>III – Proteção dos direitos da {{ title }} em qualquer tipo de conflito, inclusive os de teor judicial.</p>
        <br>
        <p>IV – Subprodutos da plataforma como congressos, simpósios e outros podem conter suas próprias politicas com outros parceiros comerciais, quando ocorrer você sera informado previamente ao uso do subproduto.</p>
        <br>
        <p>7.1. Transferência internacional de dados</p>
        <p>Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou ou possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados.</p>
        <br>
        <p>Nesse sentido, a {{ title }} se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.</p>
        <br>
        <p>Ao concordar com essa Política de Privacidade, você concorda com esse compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.</p>
        <br>
        <p>8. Cookies ou dados de navegação</p>
        <p>A {{ title }} faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do site.</p>
        <p>Em suma, os Cookies são utilizados para aprimorar a experiência de uso.</p>
        <br>
        <p>Ao acessar nosso site e consentir com o uso de Cookies, você manifesta conhecer e aceitar a utilização de um sistema de coleta de dados de navegação com o uso de Cookies em seu dispositivo.</p>
        <br>
        <p>A {{ title }} utiliza os seguintes Cookies:</p>
        <br>
        <p>DoubleClick Floodlight - Os cookies DoubleClick Floodlight nos permitem compreender se você completa algumas ações em nosso(s) website(s), após você ter visto ou clicado em um dos nossos anúncios em vídeo ou outro formato visual, entregues no Google ou em outras plataformas por meio de DoubleClick. DoubleClick utiliza esse cookie para compreender o conteúdo com que você interagiu em nosso(s) website(s), e poder posteriormente lhe entregar conteúdos publicitários sob medida para você.</p>
        <br>
        <p>Google Adwords Conversion - Os cookies de rastreamento Google Adwords Conversion fazem com que possamos entender se você completa certas ações no(s) nosso(s) website(s) depois de você ter visualizado ou clicado em algum dos nossos anúncios providenciados através do Google.</p>
        <br>
        <p>Google Analytics - Utilizamos Google Analytics para entender como as nossas campanhas de mídia funcionam e como você interage com o nosso website de modo a melhorar a experiência do usuário.</p>
        <br>
        <p>Google (Remarketing) - O Google utiliza este cookie para entender qual o conteúdo que você acessou nos nossos websites, de modo que eles possam prover anúncios baseados no conteúdo com que você interagiu no(s) nosso(s) website(s) e para que possam prover anúncios direcionados em outros websites parceiros.</p>
        <br>
        <p>Facebook - O Facebook ajuda você a se manter em contato com a sua rede através do seu site/aplicativo móvel. Nós tornamos mais fácil o compartilhamento de conteúdo de interesse no Facebook e algumas vezes podemos apresentar alguns anúncios direcionados baseados na sua interação no(s) nosso(s) website(s).</p>
        <br>
        <p>YouTube - Nós incorporamos videos ou inserimos links para vídeos no YouTube no(s) nosso(s) website(s). Como resultado, quando você visita uma página com conteúdo incorporado ou com link para YouTube você poderá receber cookies do YouTube.</p>
        <br>
        <p>Cloudflare - Gera um relatório de dispositivos e acessos gerados na pagina.</p>
        <br>
        <p>Microsoft Clarity - Gera relatório de acessos a partir dos produto e gera propagandas para usuários que acessaram a pagina ou tem e-mail e/ou numero de telefone cadastrado na plataforma.</p>
        <br>
        <p>Você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.</p>
        <br>
        <p>Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies. Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:</p>
        <br>
        <p>Se você usa o <a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer. </a></p>
        <br>
        <p>Se você usa o <a href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s">Firefox.</a></p>
        <br>
        <p>Se você usa o <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">Safari.</a></p>
        <br>
        <p>Se você usa o <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR">Google Chrome.</a></p>
        <br>
        <p>Se você usa o <a href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge.</a></p>
        <br>
        <p>Se você usa o <a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera.</a></p>
        <br>

        <p>9. Alteração desta Política de Privacidade</p>
        <p>A atual versão da Política de Privacidade foi formulada e atualizada pela última vez em: 04/09/2021.</p>
        <br>
        <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso site ou em âmbito legislativo. Recomendamos que você a revise com frequência.</p>
        <br>
        <p>Eventuais alterações entrarão em vigor a partir de sua publicação em nosso site e sempre lhe notificaremos acerca das mudanças ocorridas.</p>
        <br>
        <p>Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você as consente. </p>
        <br>
        <p>10. Responsabilidade</p>
        <p>A {{ title }} prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.</p>
        <br>
        <p>Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.</p>
        <br>
        <p>Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.</p>
        <br>
        <p>Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela {{ title }}, comprometemo-nos a segui-las. </p>
        <br>
        <p>10.1 Isenção de responsabilidade</p>
        <p>Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhuma página virtual inteiramente livre de riscos. Nesse sentido, a {{ title }} não se responsabiliza por:</p>
        <br>
        <p>I – Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.</p>

        <p>Destacamos que é de responsabilidade direta e exclusiva do DECLARANTE a veracidade das informações ora prestadas e pela autenticidade dos documentos por ele apresentados, ficando ciente este de que a falsidade das mesmas ensejará a tomada das medidas cíveis e criminais cabíveis, inclusive eventuais perdas e danos decorrentes da conduta e apuração do crime previsto no art. 299 do Código Penal Brasileiro.</p>
        <br>

        <p>II – Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da {{ title }}.</p>
        <br>
        <p>Destacamos que em caso de incidentes de segurança que possam gerar risco ou dano relevante para você ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a Autoridade Nacional de Proteção de Dados sobre o ocorrido e cumpriremos as providências necessárias.</p>
        <br>
        <p>III – Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços da {{ title }}; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/associado.</p>
        <br>
        <p>11. Encarregado de Proteção de Dados</p>
        <p>A {{ title }} disponibiliza os seguintes meios para que você possa entrar em contato conosco para exercer seus direitos de titular: </p>
        <br>
        <p>e-mail: dados@{{ title }}.com.br</p>
        <br>
        <p>Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais, através dos seguintes canais:</p>
        <br>
        <p>Eduardo Breno Farias dos Santos – 123.626.374-09</p>
        <br>
        <p>dados@{{ title }}.com.br</p>
      </div>
    </div>
  </b-card-text>
</template>

<script>
import {
  BCardText
} from 'bootstrap-vue'

export default {
  name: 'ModalTerms',
  components: {
    BCardText,
  },
  data() {
    return {
      title: '',
    }
  },
}
</script>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="logo"
          alt="Logo"
          style="max-width: 200px;"
          class="desktop-logo"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <a :href="bannerLink" target="_blank">
            <b-img
              fluid
              :src="banner"
              alt="Banner da tela de registro"
            />
          </a>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto mobile"
        >
          <div class="d-flex justify-content-center">
            <b-img
              fluid
              :src="logo"
              alt="Logo"
              style="max-width: 200px; margin-bottom: 20px"
              class="mobile-logo"
            />
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Seja bem-vindo
          </b-card-title>
          <b-card-text class="mb-2">
            <b>{{ name }}</b> {{description}}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            v-slot="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="submit"
            >
              <!-- username -->
              <b-form-group
                label="Nome"
                label-for="register-username"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  vid="username"
                  rules="required|alpha_spaces|min:3"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Digite o seu nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="usuario@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

               <!-- CRM -->
               <b-form-group
               v-if="appType == 'MEDICAL'"
                label-for="register-CRM"
                label="CRM (Opcional)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="crm"
                  vid="crm"
                  rules="integer|required_if:crmUf"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-crm"
                      v-model="crm"
                      class="form-control-merge"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      name="register-CRM"
                      placeholder="Digite o CRM"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- CRM Uf-->
              <b-form-group
                v-if="crm"
                label-for="register-Uf"
                label="UF (Opcional)"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="crmUf"
                  vid="crmUf"
                  rules="required_if:crm"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="crm > 0 ? 'is-invalid':null"
                  >
                    <b-form-select
                      id="register-crmUf"
                      v-model="crmUf"
                      class="form-control-merge"
                      type="number"
                      :options="states"
                      :state="!crmUf ? false:null"
                      name="register-crmUf"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>



              <!-- secretaria -->
              <b-form-group
              v-if="appType == 'JOURNALISTIC' "
                label="Secretaria"
                label-for="register-secretaria"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Secretaria"
                  vid="secretaria"
                  rules="required"
                >
                  <b-form-select
                    id="register-secretaria"
                    v-model="secretaria"
                    name="register-secretaria"
                    :state="errors.length > 0 ? false : null"
                    :options="secretariaOptions"
                    placeholder="Selecione a secretaria"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- cargo -->
              <b-form-group
                v-if="appType == 'JOURNALISTIC'"
                label="Cargo"
                label-for="register-cargo"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Cargo"
                  vid="cargo"
                  rules="required"
                >
                  <b-form-select
                    id="register-cargo"
                    v-model="cargo"
                    name="register-cargo"
                    :state="errors.length > 0 ? false : null"
                    :options="cargoOptions"
                    placeholder="Selecione o cargo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Senha"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:6|verify_password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- repeat password -->
              <b-form-group
                label-for="register-repeat_password"
                label="Repetir senha"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="repeatPassword"
                  vid="repeatPassword"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-repeatPassword"
                      v-model="repeatPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-repeat_password"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Eu concordo com a
                  <b-link @click="showModal">
                    política de privacidade e termos de uso
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                v-b-modal.modal-center
                variant="primary"
                block
                type="submit"
                :disabled="((invalid) || (invalidCrm==false)) || !status"
              >
                Registar
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-center mt-2">
            <span>Já possui uma conta?</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;Faça login</span>
            </b-link>
          </p>
          <!-- modal terms -->
          <b-modal
            ref="modal-terms"
            centered
            title="Política de privacidade e termos de uso"
            ok-only
            ok-title="Concordo"
          >
            <ModalTerms />
          </b-modal>
          <!-- divider -->
          <div v-if="tokenFacebook !== null || tokenGoogle !== null" class="divider my-2">
            <div class="divider-text">
              ou
            </div>
          </div>

          <!-- social buttons -->
          <div v-if="tokenFacebook !== null || tokenGoogle !== null" class="auth-footer-btn d-flex justify-content-center mb-4">
            <b-button
              v-if="tokenFacebook !== null"
              variant="facebook"
              @click="logInWithFacebook"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <GoogleLogin
              v-if="tokenGoogle !== null"
              :params="params"
              :on-success="onSuccess"
              class="btn btn-google"
            >
              <feather-icon icon="MailIcon" />
            </GoogleLogin>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import GoogleLogin from 'vue-google-login'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
// eslint-disable-next-line import/named
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ModalTerms from '@/views/ModalTerms.vue'
import axios from 'axios'

export default {
  components: {
    GoogleLogin,
    ModalTerms,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      logo: [],
      banner: [],
      bannerLink: '',
      logoPreview: null,
      bannerPreview: null,
      tokenGoogle: null, // Variável para armazenar o token do formulário 1
      tokenFacebook: null,
      name: '',
      description: '',
      isBusy: false,
      status: '',
      username: '',
      userEmail: '',
      secretaria: '',
      cargo: '',
      password: '',
      crm: '',
      crmUf: '',
      repeatPassword: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      title: '',
      appType: process.env.VUE_APP_TYPE,
      // validation
      required,
      errorCrm: '',
      errorCrmUf: '',
      email,
      invalidCrm: true,
      states: [
        { value: '', text: 'Digite o estado' },
        { value: 'AC', text: 'AC - Acre' },
        { value: 'AL', text: 'AL - Alagoas' },
        { value: 'AP', text: 'AP - Amapá' },
        { value: 'AM', text: 'AM - Amazonas' },
        { value: 'BA', text: 'BA - Bahia' },
        { value: 'CE', text: 'CE - Ceará' },
        { value: 'DF', text: 'DF - Distrito Federal' },
        { value: 'ES', text: 'ES - Espírito Santo' },
        { value: 'GO', text: 'GO - Goiás' },
        { value: 'MA', text: 'MA - Maranhão' },
        { value: 'MT', text: 'MT - Mato Grosso' },
        { value: 'MS', text: 'MS - Mato Grosso do Sul' },
        { value: 'MG', text: 'MG - Minas Gerais' },
        { value: 'PA', text: 'PA - Pará' },
        { value: 'PB', text: 'PB - Paraíba' },
        { value: 'PR', text: 'PR - Paraná' },
        { value: 'PE', text: 'PE - Pernambuco' },
        { value: 'PI', text: 'PI - Piauí' },
        { value: 'RJ', text: 'RJ - Rio de Janeiro' },
        { value: 'RN', text: 'RN - Rio Grande do Norte' },
        { value: 'RS', text: 'RS - Rio Grande do Sul' },
        { value: 'RO', text: 'RO - Rondônia' },
        { value: 'RR', text: 'RR - Roraima' },
        { value: 'SC', text: 'SC - Santa Catarina' },
        { value: 'SP', text: 'SP - São Paulo' },
        { value: 'SE', text: 'SE - Sergipe' },
        { value: 'TO', text: 'TO - Tocantins' },
      ],
      loginMethods: {
        facebook_id: process.env.VUE_APP_FACEBOOK_ID,
        google_id: process.env.VUE_APP_GOOGLE_ID,
      },

      secretariaOptions: [
        { value: '', text: 'Selecione a secretaria', disabled: true },
        { value: 'educacao', text: 'Secretaria de Educação' },
        { value: 'saude', text: 'Secretaria de Saúde' },
        { value: 'transporte', text: 'Secretaria de Transporte' },
        { value: 'cultura', text: 'Secretaria de Cultura' },
        { value: 'meio_ambiente', text: 'Secretaria de Meio Ambiente' },
        { value: 'obras_publicas', text: 'Secretaria de Obras Públicas' },
        { value: 'assistencia_social', text: 'Secretaria de Assistência Social' },
        { value: 'financas', text: 'Secretaria de Finanças' },
        { value: 'esporte_lazer', text: 'Secretaria de Esporte e Lazer' },
        { value: 'desenvolvimento_economico', text: 'Secretaria de Desenvolvimento Econômico' },
        { value: 'turismo', text: 'Secretaria de Turismo' },
        { value: 'agricultura', text: 'Secretaria de Agricultura' },
        { value: 'planejamento', text: 'Secretaria de Planejamento' },
        { value: 'comunicacao', text: 'Secretaria de Comunicação' },
        { value: 'seguranca_publica', text: 'Secretaria de Segurança Pública' },
        { value: 'habitacao', text: 'Secretaria de Habitação' },
        { value: 'recursos_humanos', text: 'Secretaria de Recursos Humanos' },
        { value: 'tecnologia_informacao', text: 'Secretaria de Tecnologia da Informação' },
        { value: 'industria_comercio', text: 'Secretaria de Indústria e Comércio' },
      ],
      cargoOptions: [
        { value: '', text: 'Selecione o cargo',  disabled: true},
        { value: 'cargo1', text: 'Cargo 1' },
        { value: 'cargo2', text: 'Cargo 2' },
        { value: 'cargo3', text: 'Cargo 3' },
      ],
    }
  },
  computed: {
    hasAlternativeLoginMethods () {
      return Object.values(this.loginMethods).some(method => method !== 'null')
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    requiredSecretaria() {
      return this.secretariaOptions.some(option => option.value === this.secretaria);
  },
  },
  watch: {

  },
  methods: {
     getTokenSocial(){

      axios.get(`${process.env.VUE_APP_API}/social_token`)
        .then(response => {
          if(response.data.response.tokenFacebook != null){
            this.tokenFacebook = response.data.response.tokenFacebook.value;
          }
          if(response.data.response.tokenGoogle != null){
            this.tokenGoogle = response.data.response.tokenGoogle.value;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPersonalizacao() {
      const logoLocalStorage = localStorage.getItem('logo');
      const bannerLocalStorage = localStorage.getItem('banner');
      const titleLocalStorage = localStorage.getItem('title');
      const descriptionLocalStorage = localStorage.getItem('description');
      const bannerLinkLocalStorage = localStorage.getItem('bannerLink');

      let requestGetOn = false

      if (typeof logoLocalStorage === 'undefined' || logoLocalStorage === null || logoLocalStorage.includes('undefined') || logoLocalStorage.includes('null')) {
        this.logo = require('@/assets/default/logo.png')
        requestGetOn = true
      } else {
        this.logo = logoLocalStorage
      }

      if (typeof bannerLocalStorage === 'undefined' || bannerLocalStorage === null || bannerLocalStorage.includes('undefined') || bannerLocalStorage.includes('null')) {
        this.banner = require('@/assets/default/banner.png')
        requestGetOn = true
      } else {
        this.banner = bannerLocalStorage
      }

      if (typeof titleLocalStorage === 'undefined' || titleLocalStorage === null || titleLocalStorage.includes('undefined') || titleLocalStorage.includes('null')) {
        this.title = 'Plataforma OP3'
        requestGetOn = true
      } else {
        this.title = titleLocalStorage
      }

      if (typeof descriptionLocalStorage === 'undefined' || descriptionLocalStorage === null || descriptionLocalStorage.includes('undefined') || descriptionLocalStorage.includes('null')) {
        this.description = 'uma plataforma completa para transmissões ao vivo, gravação, gestão e controle de cursos'
        requestGetOn = true
      } else {
        this.description = descriptionLocalStorage
      }

      if (typeof bannerLinkLocalStorage === 'undefined' || bannerLinkLocalStorage === null || bannerLinkLocalStorage.includes('undefined') || bannerLinkLocalStorage.includes('null')) {
        this.bannerLink = null
        requestGetOn = true
      } else {
        this.bannerLink = bannerLinkLocalStorage
      }

      if(requestGetOn == true){
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            if(response.data.response.logo != null && response.data.response.logo != 'undefined' && !response.data.response.logo.includes('undefined') && !response.data.response.logo.includes('null')){
              this.logo = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`
              localStorage.setItem('logo', this.logo);

            }
            if(response.data.response.banner != null && response.data.response.banner != 'undefined' && !response.data.response.banner.includes('undefined') && !response.data.response.banner.includes('null')){
              this.banner = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`
              localStorage.setItem('banner', this.banner);

            }
            if(response.data.response.name != null && response.data.response.name != 'undefined' && !response.data.response.name.includes('undefined') && !response.data.response.name.includes('null')){
              this.title = `${response.data.response.name}`
              localStorage.setItem('title', this.title);

            }
            if(response.data.response.description != null && response.data.response.description != 'undefined' && !response.data.response.description.includes('undefined') && !response.data.response.description.includes('null')){
              this.description = `${response.data.response.description}`
              localStorage.setItem('description', this.description);
            }

            if(response.data.response.bannerLink != null && response.data.response.bannerLink != 'undefined' && !response.data.response.bannerLink.includes('undefined') && !response.data.response.bannerLink.includes('null')){
              this.bannerLink = `${response.data.response.bannerLink}`
              localStorage.setItem('bannerLink', this.bannerLink);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    showModal() {
      this.$refs['modal-terms'].show()
    },
    submit() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },
    async register() {
      const register = await useJwt.register({
        name: this.username,
        email: this.userEmail,
        password: this.password,
        crm: this.crm,
        crm_uf: this.crm_uf,
        secretaria: this.secretaria,
        cargo: this.cargo,
      })
      if (register.data.status === 200) {
        const userData = await register.data.response
        useJwt.setToken(register.data.token)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Criado com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else if (register.data.status === 100) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email ou senha inválidos',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: register.data.message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onSuccess(googleUser) {
      // get Basic user information
      this.payload = {
        id: googleUser.getBasicProfile().rT,
        name: googleUser.getBasicProfile().Re,
        email: googleUser.getBasicProfile().St,
        photo: googleUser.getBasicProfile().kK
      }

      const login = await useJwt.googleLogin(
        this.payload
      )
      if (login.data.status === 200) {
        const userData = await login.data.response
        useJwt.setToken(login.data.token)
        localStorage.setItem('userData', JSON.stringify(userData))
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Logado com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Listar erro do google aqui.',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    async logInWithFacebook() {
      await this.initFacebook()
      await window.FB.login(response => {
        if (response.authResponse) {
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
          window.FB.api('/me', {
            access_token: response.authResponse.access_token,
            fields: 'name,email,picture',
          }, (res) => {
            this.payload = {
              id: res.id,
              name: res.name,
              email: res.email,
              photo: res.picture.data.url
            }
            useJwt.facebookLogin(
              this.payload
            ).then((login) => {
              if (login.data.status === 200) {
                const userData = login.data.response
                useJwt.setToken(login.data.token)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Logado com sucesso',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Listar erro do facebook aqui.',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          })
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      }, { scope: 'email' })
    },
    initFacebook() {
      return window.FB.init({
        appId: '609083367132997', // You will need to change this
        cookie: true, // This is important, it's not enabled by default
        version: 'v12.0',
      })
    },
    loadFacebookSDK() {
      (function (d, s, id) {
        let js = d.getElementsByTagName(s)[0]
        const fjs = js
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },
  },
  beforeMount() {
    this.getPersonalizacao()
    this.getTokenSocial()

  },
}
/* eslint-disable global-require */

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@media (min-width: 0px) and (max-width: 991px) {
  .mobile-logo {
    display: block;
  }
  .desktop-logo {
    display: none;
  }
  .mobile {
    padding-top: 40px;
  }
  .buttom-mobile {
    padding-bottom: 40px;
  }
}
@media (min-width: 992px){
  .desktop-logo {
    display: block;
  }
  .mobile-logo {
    display: none;
  }
}
.mb-2, .mb-1 {
  text-align: center;
}
</style>